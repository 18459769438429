import {useState} from 'react'
import { Suspense } from "react"
import { Canvas } from "@react-three/fiber"
import { Environment, Stars, OrbitControls, PerspectiveCamera } from "@react-three/drei"
import Plate from './Plate';
import Loader from './Loader';
import ModelLogo from './ModelLogo';
import ModelStatue from './ModelStatue';

export default function Scene() {
  const [toggle, setToggle] = useState(false)

  const handleToggle = () => {
    setToggle(!toggle)
  }
  
  return (
    <div>
      
      {toggle ? 
      
      <div className="viewer">
        <Suspense fallback={<Loader />}>
          <Canvas shadows dpr={[1, 2]} >
          <PerspectiveCamera position={[0, 0, 25]} fov={[12]} makeDefault shadows/>
            <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
            <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[-10, -15, -10]} castShadow />
            
              <ModelStatue />
              <Environment preset="forest" />
              <Plate />
              <ModelLogo/>
              <Stars radius={5} depth={10} count={10000} factor={2} saturation={0} fade speed={1} />
            
            <OrbitControls
              minDistance={15}
              maxDistance={50} 
              enablePan={false} 
              autoRotate autoRotateSpeed={1.5}
              />
          </Canvas>
        </Suspense>
        
      </div> : <div className="fullscreen"><img src="/images/unrevealed.svg" alt="unrevealed" width="100%" height="100%"/></div>}
      <div className="changer" onClick={handleToggle}>
      <img src="/images/switch.png" alt="switcher" width="50" height="51" />
      </div>
      {/* <button className="changer" onClick={handleToggle}>Change</button> */}
    </div>
  )
}