import { useLoader } from "@react-three/fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { useAnimations } from "@react-three/drei"

const ModelStatue = () => {
    const gltf = useLoader(GLTFLoader, "/mascot/statue.glb");
    const {actions} = useAnimations(gltf.animations, gltf.scene)
    actions['Dance 01'].play()
    return (
      <>
        <primitive 
          object={gltf.scene} 
          scale={0.04} 
          position={[0, -1, 0]}
          rotation={[0,3.5,0]} 
          />
      </>
    );
  };

  export default ModelStatue