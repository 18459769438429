import { useLoader } from "@react-three/fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

const ModelLogo = () => {
    const gltf = useLoader(GLTFLoader, "/logo/yolologovoxel.gltf");
    return (
      <>
        <primitive 
          object={gltf.scene} 
          scale={0.045} 
          position={[0, -0.7, 1]}
          rotation={[0,0,0]}
          />
      </>
    );
  };

  export default ModelLogo