import React from 'react'

function Plate() {
  return (
  <>
    <mesh receiveShadow position={[0,-1.12,0]}>
      <cylinderGeometry args={[1.6, 1.6, 0.25, 64]} />
      <meshStandardMaterial
        color={"#C0C0C0"}
        />
    </mesh>
    <mesh receiveShadow position={[0,-1.35,0]}>
      <cylinderGeometry args={[1.4, 1.4, 0.25, 64]} />
      <meshStandardMaterial
        color={"#C0C0C0"}
        />
    </mesh>
    <mesh receiveShadow position={[0,-1.58,0]}>
      <cylinderGeometry args={[1.6, 1.6, 0.25, 64]} />
      <meshStandardMaterial
        color={"#C0C0C0"}
        />
    </mesh>
  </>
  )
}

export default Plate